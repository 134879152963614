body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --brand-color: #1ba94c;
  --brand-rgb: 27, 169, 76;
  --brand-hover: #22d560;
  --brand-dark: #147d38;
  --brand-disabled: #ccf7db;
  --brand-box-shadow: rgba(27, 169, 76, 0.5);
  --brand-box-shadow-hover: rgba(27, 169, 76, 0.4);
  --primary-color: #1ba94c;
  --primary-rgb: 27, 169, 76;
  --primary-hover: #22d560;
  --primary-dark: #147d38;
  --primary-disabled: #ccf7db;
  --primary-box-shadow: rgba(27, 169, 76, 0.5);
  --primary-box-shadow-hover: rgba(27, 169, 76, 0.4);
  --secondary-color: #097bbf;
  --secondary-rgb: 9, 123, 191;
  --secondary-hover: #0b9af0;
  --secondary-dark: #075c8e;
  --secondary-disabled: #caeafd;
  --secondary-box-shadow: rgba(9, 123, 191, 0.5);
  --secondary-box-shadow-hover: rgba(9, 123, 191, 0.4);
  --error-color: #a93c32;
  --error-rgb: 169, 60, 50;
  --error-hover: #c85146;
  --error-dark: #822e26;
  --error-disabled: #f7e5e3;
  --error-box-shadow: rgba(169, 60, 50, 0.5);
  --error-box-shadow-hover: rgba(169, 60, 50, 0.4);
  --success-color: #22a97a;
  --success-rgb: 34, 169, 122;
  --success-hover: #2bd399;
  --success-dark: #197f5b;
  --success-disabled: #d4f6ea;
  --success-box-shadow: rgba(34, 169, 122, 0.5);
  --success-box-shadow-hover: rgba(34, 169, 122, 0.4);
  --disabled-color: #6EA989;
  --app-background: linear-gradient(to bottom, #fff 0%, #f3f7f7 100%);
  --font-family-sans-serif: 'Open Sans', sans-serif;
  --heading-color: #3a424e;
  --link-color: #5fa6ff;
  --link-color-hover: #447dbd;
  --disabled-text-color: #b7c9cc;
  --toolbar-background-color: #39424e;
  --toolbar-box-shadow-color: rgba(115, 143, 147, 0.3);
  --toolbar-border-color: #28313d;
  --card-shadow-color: rgba(99, 118, 129, 0.3);
  --card-hover-shadow-color: rgba(99, 118, 129, 0.5);
  --card-margin-gutter: 0px;
}

h4 {
  color: var(--heading-color);
  font-size: 1.2em;
}

h4 {
  display: flex;
  justify-content: center;
}

ul.styled {
  list-style-type: none;
}

ul.styled,
ol.styled {
  margin: 0;
}

.mt-50 {
  margin-top: 50px !important;
}

ul {
  padding-left: 0px;
}

.li-content {
  height: 25px;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.layout-row {
  display: flex;
  flex-direction: row;
}

button.x-small.icon-only i,
a.button.x-small.icon-only i {
  font-size: 16px;
  width: 16px;
  height: 16px;
}

button:disabled i {
  color: var(--disabled-text-color) !important;
}


button.disabled,
button:disabled {
  background: var(--brand-disabled);
  box-shadow: none;
}

button.icon-only i {
  color: var(--brand-color);
}


button.x-small.icon-only,
a.button.x-small.icon-only {
  width: 24px;
  min-height: 24px;
  height: 24px;
}

button.x-small.icon-only,
a.button.x-small.icon-only {
  width: 24px;
  min-height: 24px;
  height: 24px;
}

button.icon-only:disabled,
a.button.icon-only:disabled {
  background: transparent;
}

button.disabled,
button:disabled {
  background: var(--brand-disabled);
  box-shadow: none;
}

button.x-small,
a.button.x-small {
  font-size: 0.7em;
}

button.disabled,
button:disabled,
a.button.disabled,
a.button:disabled {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  color: var(--disabled-text-color) !important;
}

button.x-small,
a.button.x-small {
  min-height: 28px;
}


button.icon-only,
a.button.icon-only {
  width: 36px;
  height: 36px;
  min-height: 36px;
  padding: 0;
  margin: 8px 12px;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 100%;
}

.mx-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

button.danger.icon-only i {
  color: var(--error-color);
}