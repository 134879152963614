.layout-row {
    display: flex;
    flex-direction: row;
}

.card {
    transition: height 0.5s ease-in-out;
    will-change: height;
}

.card-text {
    min-width: 236px;
    min-height: 350px;
}


.mt-20 {
    margin-top: 20px !important;
}


.justify-content-center {
    justify-content: center;
}

.layout-column {
    display: flex;
    flex-direction: column;
}


input {
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1), 0 0 0 transparent;
    border: 0;
    border-radius: 0;
    background-color: #f3f7f7;
    color: #0e141e;
    height: 36px;
    outline: none;
    font-size: 14px;
    padding: 0 12px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.1s ease-in-out;
}

.ml-30 {
    margin-left: 30px !important;
}

button {
    color: #ffffff;
    background: var(--brand-color);
    box-shadow: 0 4px 12px var(--brand-box-shadow);
}

input.large {
    height: 40px;
    font-size: 16px;
}

button,
a.button {
    outline: none;
    padding: 0 1.528em;
    min-height: 44px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 8px 12px;
    transition: box-shadow 0.1s ease;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    font-family: var(--font-family-sans-serif);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.justify-content-between {
    justify-content: space-between;
}

.layout-row {
    display: flex;
    flex-direction: row;
}

ul.styled li,
ol.styled li {
    background: white;
    min-width: 200px;
    padding: 8px 12px 8px 24px;
    margin: 16px 0;
    box-shadow: 0 4px 12px #eaeaea;
}