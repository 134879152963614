:root {
  --brand-color: #1ba94c;
  --brand-rgb: 27, 169, 76;
  --brand-hover: #22d560;
  --brand-dark: #147d38;
  --brand-disabled: #ccf7db;
  --brand-box-shadow: rgba(27, 169, 76, 0.5);
  --brand-box-shadow-hover: rgba(27, 169, 76, 0.4);
  --primary-color: #1ba94c;
  --primary-rgb: 27, 169, 76;
  --primary-hover: #22d560;
  --primary-dark: #147d38;
  --primary-disabled: #ccf7db;
  --primary-box-shadow: rgba(27, 169, 76, 0.5);
  --primary-box-shadow-hover: rgba(27, 169, 76, 0.4);
  --secondary-color: #097bbf;
  --secondary-rgb: 9, 123, 191;
  --secondary-hover: #0b9af0;
  --secondary-dark: #075c8e;
  --secondary-disabled: #caeafd;
  --secondary-box-shadow: rgba(9, 123, 191, 0.5);
  --secondary-box-shadow-hover: rgba(9, 123, 191, 0.4);
  --error-color: #a93c32;
  --error-rgb: 169, 60, 50;
  --error-hover: #c85146;
  --error-dark: #822e26;
  --error-disabled: #f7e5e3;
  --error-box-shadow: rgba(169, 60, 50, 0.5);
  --error-box-shadow-hover: rgba(169, 60, 50, 0.4);
  --success-color: #22a97a;
  --success-rgb: 34, 169, 122;
  --success-hover: #2bd399;
  --success-dark: #197f5b;
  --success-disabled: #d4f6ea;
  --success-box-shadow: rgba(34, 169, 122, 0.5);
  --success-box-shadow-hover: rgba(34, 169, 122, 0.4);
  --disabled-color: #6EA989;
  --app-background: linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
  --font-family-sans-serif: 'Open Sans', sans-serif;
  --heading-color: #3a424e;
  --link-color: #5fa6ff;
  --link-color-hover: #447dbd;
  --disabled-text-color: #b7c9cc;
  --toolbar-background-color: #39424e;
  --toolbar-box-shadow-color: rgba(115, 143, 147, 0.3);
  --toolbar-border-color: #28313d;
  --card-shadow-color: rgba(99, 118, 129, 0.3);
  --card-hover-shadow-color: rgba(99, 118, 129, 0.5);
  --card-margin-gutter: 0px;
}

body {
  background: var(--app-background);
  min-height: calc(100vh);
}

.App {
  text-align: center;
  padding: auto;
  margin: auto;
}


.hydrated {
  visibility: inherit;
}

nav.app-header {
  height: 60px;
  min-height: 60px;
  line-height: 60px;
  background: var(--toolbar-background-color);
  border-bottom: 1px solid var(--toolbar-border-color);
  z-index: 900;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 3px 10px 0 var(--toolbar-box-shadow-color);
  padding: 0 1em;
}

.app-title {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 700;
}

nav.app-header .logo {
  height: 44px;
  color: var(--primary-color);
  font-size: 1.2em;
}

.card.outlined {
  background: white;
  border: thin solid rgba(var(--primary-rgb), 0.1);
  box-shadow: none;
}

.card .card-text {
  padding: 1em;
}

.mt-0 {
  margin-top: 0px !important;
}

.ml-20 {
  margin-left: 20px !important;
}